<template>
    <div class="modal is-active" id="modal-apagar">
        <div class="modal-background"></div>
        <div class="modal-content has-background-white">
            <section class="section">
              <p class=" has-text-left  is-size-5 fonte-1 has-text-dark">
                <slot name="title"></slot>
              </p>
              <div class="notification is-danger is-light " v-if="props.dangerAlert">
                  <hr class="">
                <p class="has-text-left  is-size-6 fonte-1">
                    {{ props.dangerAlert }}
                </p>
              </div>
              <hr>
              <div class="field is-grouped">
                <div class="control is-expanded">
                  <button class="button is-fullwidth has-text-weight-bold " @click="emits('cancel')">
                    {{ props.noButtonText }}
                  </button>
                </div>
                <div class="control is-expanded">
                  <button class="button is-fullwidth is-success has-text-white" :class="{ 'is-danger': props.danger }" @click="emits('confirm')">
                    <span>
                        {{ props.yesButtonText }}
                    </span>
                  </button>
                </div>
              </div>
          </section>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    dangerAlert:{
        type: String,
        default: null
    },
    danger:{
        type: Boolean,
        default: false
    },
    noButtonText:{
        type: String,
        default: 'Cancelar'
    },
    yesButtonText:{
        type: String,
        default: 'Confirmar'
    }
})

const emits = defineEmits(['confirm','cancel'])


</script>

<style lang="scss" scoped>

.modal-content{
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    #modal-apagar .modal-content{
      max-width: 90dvw !important;
    }
}

</style>