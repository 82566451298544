<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">

      <NuxtLink class="navbar-item" id="logo" to="/campanhas">
        <img src="/img/app_icon.webp" class="mr-2" alt="Logo MD1 Sender" width="24" height="24">
        <h1 class="title is-size-6 has-text-centered has-text-light">MD1 Sender</h1>
      </NuxtLink>

      <a role="button" class="navbar-burger" :class="{ 'is-active': navbarBurgerActive }" aria-label="menu"
        aria-expanded="false" data-target="navbarBasicExample" @click="navbarBurgerActive = !navbarBurgerActive">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': navbarBurgerActive }"
      @click="navbarBurgerActive = false">

      <div class="navbar-start is-hidden-desktop" @click="navbarBurgerActive = false">

        <NuxtLink class="is-ghost navbar-item" :to="`/campanhas`" alt=' Ir para Minhas Campanhas'>
          <Icon name="mdi:cards-variant" class="mr-1" size="0.8rem" color="white" alt=' Ir para Minhas Campanhas' />
          Minhas Campanhas
        </NuxtLink>

        <NuxtLink class="is-ghost navbar-item" :to="`/disparo`">
          <Icon name="mdi:send" class="mr-1" size="0.8rem" color="white" />
          Disparar Campanha
        </NuxtLink>

        <NuxtLink class="is-ghost navbar-item" :to="`/agendamentos`">
          <Icon name="mdi:calendar-clock" class="mr-1" size="0.8rem" color="white" />
          Disparos Agendados
        </NuxtLink>

        <NuxtLink class="is-ghost navbar-item" :to="`/coletar`">
          <Icon name="mdi:account-group" class="mr-1" size="0.8rem" color="white" />
          Coletar Grupos
        </NuxtLink>

        <NuxtLink class="is-ghost navbar-item" :to="`/disparos`">
          <Icon name="mdi:history" class="mr-1" size="0.8rem" color="white" />
          Histórico de Disparos
        </NuxtLink>

        <!-- <NuxtLink class="is-ghost navbar-item" :to="`/preferencias`">
          <Icon name="mdi:tools" class="mr-1" size="0.8rem" color="white" />
          Preferências
        </NuxtLink> -->
      </div>
      <hr class="my-1 mx-3">
      <div class="navbar-end">
        <a class=" is-ghost navbar-item" :disabled="wppDisparoStore.disparando" @click="ativo = true">
          <Icon name="mdi:whatsapp" :color="useWppAuthStore().conectado ? '#5EFF77' : 'red'" size="1.1rem"
            class="mr-2" />

          <span v-if="!useWppAuthStore().conectado">
            Desconectado
          </span>
          <span v-else-if="useWppAuthStore().phoneConnected">
            Conectado
          </span>
        </a>
        <a class=" is-ghost navbar-item" :disabled="wppDisparoStore.disparando" @click="modalArquivos = true">
          <Icon name="mdi:archive" color="white" size="1.1rem" class="mr-2" />
          Sender Drive
        </a>
      </div>



      <div class="navbar-end is-hidden-desktop" @click="navbarBurgerActive = false">
        <!-- <NuxtLink class="is-ghost navbar-item" :to="`/ajuda`">
          <Icon name="mdi:help-circle" class="mr-1" size="0.8rem" color="white" />
          Ajuda & Suporte
        </NuxtLink> -->
        <a class="navbar-item is-ghost" @click="navigateTo(`/alterar-senha`)">
          <Icon name="mdi:lock-reset" class="mr-1" size="0.9rem" />
          <span>Alterar Senha</span>
        </a>
        <a class="is-ghost navbar-item" @click="sair()">
          <span class="icon">
            <Icon name="mdi:logout" class="mr-1" size="0.9rem" />
          </span>
          <span>
            <strong>Sair</strong>
          </span>
        </a>

      </div>
    </div>

    <div class="navbar-end is-hidden-touch">
      <div class="navbar-item has-dropdown  is-hidden-touch mr-3" id="dropdown-usuario"
        :class="{ 'is-active': dropdownAtivo }">

        <a role="button" class="navbar-link  fonte-2  is-size-6 " id="dropdown-link"
          @click="dropdownAtivo = !dropdownAtivo">
          <Icon name="mdi:account-outline" class="pr-1" id="avatar" />
          <span>{{ authStore.email }}</span>
        </a>

        <div class="navbar-dropdown is-right">
          <div class="navbar-item">
            <div class="columns is-multiline">
              <div class="column px-0 pb-0">
                <a class="navbar-item" @click="navigateTo(`/alterar-senha`); dropdownAtivo = false">
                  <span class="icon">
                    <Icon name="mdi:lock-reset" class="mr-1" />
                  </span>
                  <span>
                    <strong>Alterar Senha</strong>
                  </span>
                </a>
              </div>
              <div class="column px-0 pt-1">
                <a href="#" class="navbar-item has-text-danger" @click="sair(); dropdownAtivo = false">
                  <span class="icon">
                    <Icon name="mdi:logout" class="mr-1" />
                  </span>
                  <span>
                    <strong>Sair</strong>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </nav>

  <KeepAlive>
    <ModalConectar v-if="ativo == true" @fechar="ativo = false" />
  </KeepAlive>

  <ModalArquivos v-if="modalArquivos" @fechar="modalArquivos = false" :is-select-mode="false" />

</template>

<script setup>

const authStore = useAuthStore();
const ativo = ref(false);
const wppDisparoStore = useWppDisparoStore();
const emit = defineEmits(['abrirModalConexao'])
const navbarBurgerActive = ref(false);

const dropdownAtivo = ref(false)
const modalArquivos = ref(false)

function sair() {
  authStore.logout()
  navigateTo('/login')
}

</script>

<style lang="scss" scoped>
#logo {
  width: 10rem;
}

.navbar {
  height: 5dvh;
  z-index: 30;
}

a.is-ghost {
  display: flex;
}

.navbar-menu.is-active {
  z-index: 99;
  position: absolute;
  width: 100%;
  right: 0;
}

.navbar-dropdown {
  border-radius: 0px 0px 5px 5px;
}

.sender-drive-button {

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & p {
    display: flex;
  }

  & progress {
    height: 5px;
    border-radius: 0px;
  }
}
</style>