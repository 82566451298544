<template>
  <div class="modal is-active">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head py-4 pl-4 pr-4 has-background-dark">
        <p class="modal-card-title has-text-light icon-text">
          <Icon name="mdi:whatsapp" class="icon" color="white" size="1.5rem" />
          <span>
            Sessão WhatsApp
          </span>
        </p>
        <button class="delete is-large" aria-label="close" @click="emit('fechar')"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-vcentered is-centered is-multiline">
          <div class="column is-12 has-text-centered">
            <div class="info" v-if="wppAuthStore.carregando && !wppAuthStore.qrCode">
              <div class="loader-box">
                <div class="loader"></div>
                <p class="has-text-dark is-size-4-desktop is-size-6-touch">{{ status }}</p>
                <button class="button is-fullwidth  is-medium is-responsive"
                  @click="cancelarAutenticacao()">Cancelar</button>
              </div>
            </div>

            <div class="info" v-else-if="wppAuthStore.status === 'error'">
              <div class="alert-popup-container">
                <div class="failure-xmark">
                  <div class="x-icon">
                    <span class="icon-line line-left"></span>
                    <span class="icon-line line-right"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </div>
                <div class="alert-popup-title has-text-danger is-size-6-touch">{{ wppAuthStore.error }}</div>
                <div class="alert-popup-confirm">
                  <hr>
                  <button class="button is-dark is-fullwidth  is-medium is-responsive" @click="autenticar()">
                    <span class="icon">
                      <Icon name="mdi:restart" />
                    </span>
                    <span>
                      Tentar Novamente
                    </span>
                  </button>
                </div>
                <button class="button mt-2 is-dark is-outlined is-fullwidth is-responsive is-medium"
                  @click="emit('fechar')">
                  <span>
                    Cancelar
                  </span>
                </button>
              </div>
            </div>

            <div class="info info-qrcode" v-else-if="wppAuthStore.qrCode">
              <img :src="wppAuthStore.qrCode" alt="" id="qrcode">
              <p class="has-text-weight-bold has-text-dark is-size-6-desktop is-size-7-touch mb-0">
                Leia o QR-Code no WhatsApp <br>
                <a href="#" @click="modalAjuda = true">
                  <Icon name="mdi:help-circle-outline" class="icon pb-1" />
                  <span>
                    Ajuda
                  </span>
                </a>
              </p>
            </div>

            <div class="info" v-else-if="wppAuthStore.conectado">
              <div class="alert-popup-container">
                <div class="success-checkmark">
                  <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </div>
                <div class="alert-popup-title  is-size-4-desktop is-size-5-touch mt-6">
                  CONECTADO: {{ formatPhone(wppAuthStore.phoneConnected) }}
                </div>
                <div class="alert-popup-confirm">
                  <hr>
                  <button class=" button is-dark is-fullwidth is-medium is-responsive" @click="autenticar()">
                    <span class="icon">
                      <Icon name="mdi:restart" />
                    </span>
                    <span>
                      Verificar Novamente
                    </span>
                  </button>
                  <button class="button mt-2 is-danger has-text-white is-fullwidth is-responsive  is-medium"
                    @click="modalDesconectar = true">
                    <span class="icon">
                      <Icon name="ant-design:disconnect-outlined" />
                    </span>
                    <span>
                      Desconectar Dispositivo
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="info" v-else-if="!wppAuthStore.conectado">
              <div class="alert-popup-container">
                <div class="failure-xmark">
                  <div class="x-icon">
                    <span class="icon-line line-left"></span>
                    <span class="icon-line line-right"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </div>
                <div class="alert-popup-title  has-text-danger is-size-4-desktop is-size-6-touch">DESCONECTADO</div>
                <div class="alert-popup-confirm">
                  <hr>
                  <button class="button  is-dark is-fullwidth is-medium is-responsive" @click="autenticar()">
                    <span class="icon">
                      <Icon name="material-symbols:smartphone-outline" />
                    </span>
                    <span>
                      Conectar Dispositivo
                    </span>
                  </button>
                  <button class="button mt-2 is-dark is-outlined is-fullwidth  is-medium is-responsive"
                    @click="emit('fechar')">
                    <span>
                      Cancelar
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="modal is-active" v-if="modalAjuda">
    <div class="modal-background" @click="modalAjuda = false"></div>
    <div class="modal-content">
      <div class="box py-5">
        <p class="is-size-5">
          <Icon name="mdi:help-circle-outline" class="icon pb-1" />
          <span>
            <b> Conectar um dispositivo usando o código QR:</b>
          </span>
        </p>
        <br>
        <p><b>Android</b></p>
        <div class="content">
          <ol>
            <li>Abra o <b>WhatsApp</b> no seu celular Android principal.</li>
            <li>Toque no ícone <img alt="more options" src="~/assets/images/android_menu.png" id="menu-icon"> e, em
              seguida, em
              <b>Dispositivos conectados > Conectar um dispositivo.</b>
            </li>
            <li>Desbloqueie seu celular principal.</li>
            <li>Aponte seu celular Android para a tela do dispositivo que você deseja conectar para escanear o código
              QR.</li>
          </ol>
        </div>
        <p><b>iPhone</b></p>
        <div class="content">
          <ol>
            <li>Abra o <b>WhatsApp</b> no seu celular iPhone principal.</li>
            <li>Toque em <b>Configurações > dispositivos conectados > Conectar um dispositivo</b>.</li>
            <li>Se você estiver usando o iOS 14 ou posterior, desbloqueie seu celular.</li>
            <li>Aponte seu celular para a tela do dispositivo que você deseja conectar para escanear o código QR.</li>
          </ol>
        </div>
      </div>

    </div>
    <button class="modal-close is-large" aria-label="close" @click="modalAjuda = false" </button>
  </div>
  <ModalConfirmarAcao @confirm="modalDesconectar = false; desconectar()" @cancel="modalDesconectar = false"
    :danger="true" :yes-button-text="'Desconectar'" v-if="modalDesconectar">
    <template #title>
      <p class="has-text-dark">Deseja mesmo <strong class="has-text-dark">desconectar</strong> de sua sessão?</p>
    </template>
  </ModalConfirmarAcao>
</template>

<script setup>
import formatPhone from '~/utils/formatPhone';

const emit = defineEmits(['fechar'])
const { $api } = useNuxtApp()

const image = ref(null)
const verificando = ref(false)
const wppAuthStore = useWppAuthStore()
const autenticando = ref(false)
const erro = ref(false)
const status = ref('Sessão WhatsApp')
const mensagem = ref('Tentativas Esgotadas')
let qrcodeUpdater = ref(null)

const modalDesconectar = ref(false)
const modalAjuda = ref(false)

onActivated(() => {
  if (wppAuthStore.status === 'authentication_started') {
    status.value = 'Carregando QR-Code...'
  }
})

onDeactivated(() => {
  clearInterval(qrcodeUpdater.value)
})

async function autenticar() {

  status.value = 'Carregando QR-Code...'
  wppAuthStore.status = 'authentication_started'
  erro.value = false

  erro.value = false
  $api('/api/autenticar/', { method: 'get' })
    .then(response => {
      erro.value = false
      image.value = null
      wppAuthStore.status = 'authenticated'
      status.value = 'Autenticação Finalizada'

    }).catch(error => {

      autenticando.value = false
      erro.value = true


      if (error.status === 409) {
        status.value = "Falha ao Verificar"
        mensagem.value = "Tente mais tarde"
      }
      if (error.status === 522) {
        status.value = "Falha ao autenticar"
        mensagem.value = "Tentativas esgotadas"
        wppAuthStore.conectado = false
      }
      if (error.status === 500) {
        status.value = "Falha ao autenticar"
        mensagem.value = "Ocorreu um ERRO"
        wppAuthStore.conectado = false
      }
    })

}

async function cancelarAutenticacao() {
  await $api('/api/cliente-destroy/', { method: 'get' })
    .then(response => {
      console.log('cliente matado')
      wppAuthStore.$reset()
    })
}

async function desconectar() {
  verificando.value = true
  wppAuthStore.status = 'disconnection_started'
  status.value = 'Desconectando...'
  await $api('/api/desconectar-sessao/', { method: 'get' })
    .then(response => {
      modalDesconectar.value = false
      verificando.value = false
      wppAuthStore.status = 'disconnected'
    })
    .catch(error => {
      if (error.status === 409) {
        useErrorToast('Falha ao desconectar, aguarde um momento e tente novamente')
      }
      else {
        useErrorToast('Falha ao desconectar, tente novamente')
      }
    })
}

</script>

<style lang="scss" scoped>
.modal-card {
  justify-content: center;
  //max-width: 50dvw;
  aspect-ratio: 1;
}

@media (max-width: 768px) {
  .modal-card {
    aspect-ratio: unset;
    max-width: 90dvw;
    //height: 50dvh;
  }

}

.modal-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-card-head {
  border-radius: 10px 10px 0px 0px;
}


.modal-card-body {
  overflow: hidden;
  display: flex;
  min-height: 40dvh;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 10px 10px;

  & .columns {
    width: 100%;
    height: 100%;
  }
}

.column.is-12 {
  position: relative;
  width: 100%;
  height: 100%;
}

.column.is-12 div.info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:has(img) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

}

#botao-resetar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

#imagem-error {
  border: solid grey 2px;
  opacity: 0.7;
  filter: blur(3px);
}

img#qrcode {
  width: 70%;
  height: auto;
  border: 3px solid black;
  border-radius: 5px;
}

img#menu-icon {
  position: relative;
  height: 25px;
  width: 25px;
  display: inline-flex;
}

.loader-box {
  position: relative;
  display: flex;
  height: 100%;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}


.loader {
  color: black;
  font-size: 12px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border-color: #FFFFFF;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em,
      2em -2em 0 0em, 3em 0 0 -1em,
      2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
      3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em,
      2em -2em 0 0, 3em 0 0 0.2em,
      2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
      3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.alert-popup-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.alert-popup-title {
  font-size: 35px;
  font-weight: bold;
  color: #4CAF50;
  margin-top: 15px;
  //margin-bottom: 15px;
  text-transform: uppercase;
  z-index: 2;
  position: relative;
}



.success-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
      z-index: 2;
    }

    .icon-line {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.failure-xmark {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  position: relative;
}

.x-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid rgb(255, 102, 133);
  margin: 0 auto;
}

.x-icon .icon-line {
  height: 5px;
  background-color: rgb(255, 102, 133);
  display: block;
  border-radius: 2px;
  position: absolute;
  top: 38px;
  width: 47px;
}

.x-icon .line-left {
  left: 16px;
  transform: rotate(45deg);
  animation: icon-line-left 0.75s;
}

.x-icon .line-right {
  right: 16px;
  transform: rotate(-45deg);
  animation: icon-line-right 0.75s;
}

@keyframes icon-line-left {
  0% {
    width: 0;
  }

  65% {
    width: 30;
  }

  84% {
    width: 55px;
  }

  100% {
    width: 47px;
  }
}

@keyframes icon-line-right {
  0% {
    width: 0;
  }

  65% {
    width: 30;
  }

  84% {
    width: 55px;
  }

  100% {
    width: 47px;
  }
}
</style>